var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vx-card',{attrs:{"title":"Add Postcode"}},[_c('template',{slot:"actions"},[_c('vs-button',{attrs:{"color":"dark"},on:{"click":_vm.handleBack}},[_vm._v("Back")])],1),_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitUser)}}},[_c('vs-row',{staticClass:"mb-base"},[_c('vs-col',{staticClass:"m-2",attrs:{"vs-type":"flex","vs-justify":"flex-end","vs-align":"center","vs-w":"2"}},[_c('span',[_vm._v("Preference:")])]),_c('vs-col',{attrs:{"vs-type":"flex","vs-justify":"flex-start","vs-align":"center","vs-w":"3"}},[_c('ValidationProvider',{staticClass:"w-full",attrs:{"rules":{ required: true, max: 100 },"custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-select',{staticClass:"w-full",attrs:{"name":"preference"},model:{value:(_vm.preference),callback:function ($$v) {_vm.preference=$$v},expression:"preference"}},_vm._l((_vm.preferenceList),function(item,index){return _c('vs-select-item',{key:index,attrs:{"value":item,"text":item}})}),1),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('vs-row',{staticClass:"mb-base"},[_c('vs-col',{staticClass:"m-2",attrs:{"vs-type":"flex","vs-justify":"flex-end","vs-align":"center","vs-w":"2"}},[_c('span',[_vm._v("Search Address:")])]),_c('vs-col',{attrs:{"vs-type":"flex","vs-justify":"flex-start","vs-align":"center","vs-w":"6"}},[_c('GeoSuggest',{on:{"parentToChild":_vm.parentToChild}})],1)],1),_c('vs-row',{staticClass:"mb-base"},[_c('vs-col',{staticClass:"m-2",attrs:{"vs-type":"flex","vs-justify":"flex-end","vs-align":"center","vs-w":"2"}},[_c('span',[_vm._v("Suburb:")])]),_c('vs-col',{attrs:{"vs-type":"flex","vs-justify":"flex-start","vs-align":"center","vs-w":"3"}},[_c('ValidationProvider',{staticClass:"w-full",attrs:{"rules":{ regex: /^[a-zA-Z0-9 -]+$/, required: true, max: 100 },"custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"name":"suburb"},model:{value:(_vm.postCode.suburb),callback:function ($$v) {_vm.$set(_vm.postCode, "suburb", $$v)},expression:"postCode.suburb"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('vs-row',{staticClass:"mb-base"},[_c('vs-col',{staticClass:"m-2",attrs:{"vs-type":"flex","vs-justify":"flex-end","vs-align":"center","vs-w":"2"}},[_c('span',[_vm._v("State:")])]),_c('vs-col',{attrs:{"vs-type":"flex","vs-justify":"flex-start","vs-align":"center","vs-w":"2"}},[_c('ValidationProvider',{staticClass:"w-full",attrs:{"rules":{ required: true, max: 100 },"custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-select',{staticClass:"w-full",attrs:{"name":"state"},model:{value:(_vm.postCode.state),callback:function ($$v) {_vm.$set(_vm.postCode, "state", $$v)},expression:"postCode.state"}},_vm._l((_vm.stateList),function(item,index){return _c('vs-select-item',{key:index,attrs:{"value":item.code,"text":item.name}})}),1),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('vs-row',{staticClass:"mb-base"},[_c('vs-col',{staticClass:"m-2",attrs:{"vs-type":"flex","vs-justify":"flex-end","vs-align":"center","vs-w":"2"}},[_c('span',[_vm._v("Postcode:")])]),_c('vs-col',{attrs:{"vs-type":"flex","vs-justify":"flex-start","vs-align":"center","vs-w":"2"}},[_c('ValidationProvider',{staticClass:"w-full",attrs:{"name":"Postcode","rules":{ regex: /^[a-zA-Z0-9 -]+$/, required: true, max: 10 },"custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",model:{value:(_vm.postCode.postcode),callback:function ($$v) {_vm.$set(_vm.postCode, "postcode", $$v)},expression:"postCode.postcode"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('vs-row',{staticClass:"mb-base"},[_c('vs-col',{staticClass:"m-2",attrs:{"vs-type":"flex","vs-justify":"flex-end","vs-align":"center","vs-w":"2"}},[_c('span')]),_c('vs-col',{attrs:{"vs-type":"flex","vs-justify":"flex-start","vs-align":"center","vs-w":"3"}},[_c('vs-button',{staticClass:"mr-3 mb-2",on:{"click":function($event){$event.preventDefault();return _vm.submitUser.apply(null, arguments)}}},[_vm._v("Submit")])],1)],1)],1)]}}])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }