const searchListOfStates = function() {
  var listOfStates = [
    {
      name: "New South Wales",
      code: "NSW"
    },
    {
      name: "Queensland",
      code: "QLD"
    },
    {
      name: "Victoria",
      code: "VIC"
    },
    {
      name: "Northern Territory",
      code: "NT"
    },
    {
      name: "South Australia",
      code: "SA"
    },
    {
      name: "Western Australia",
      code: "WA"
    },
    {
      name: "Tasmania",
      code: "TAS"
    },
    {
      name:"Australian Capital Territory",
      code: "ACT"
    }
  ];
  return listOfStates;
};

export const treeMixData = {
  searchListOfStates
};
