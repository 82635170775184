<template>
<vx-card title="Add Postcode">
    <template slot="actions">
        <vs-button @click="handleBack" color="dark">Back</vs-button>
    </template>
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submitUser)">

            <vs-row class="mb-base">
                <vs-col class="m-2" vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
                    <span>Preference:</span>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
                    <ValidationProvider class="w-full" :rules="{ required: true, max: 100 }" v-slot="{ errors }" :custom-messages="customMessages">
                        <vs-select name="preference" v-model="preference" class="w-full">
                            <vs-select-item :key="index" :value="item" :text="item" v-for="(item, index) in preferenceList" />
                        </vs-select>
                        <span class="text-danger text-sm">{{ errors[0] }}</span>
                    </ValidationProvider>
                </vs-col>
            </vs-row>

            <vs-row class="mb-base">
                <vs-col class="m-2" vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
                    <span>Search Address:</span>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">
                    <GeoSuggest @parentToChild="parentToChild" />
                </vs-col>
            </vs-row>

            <vs-row class="mb-base">
                <vs-col class="m-2" vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
                    <span>Suburb:</span>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
                    <ValidationProvider class="w-full" :rules="{ regex: /^[a-zA-Z0-9 -]+$/, required: true, max: 100 }" v-slot="{ errors }" :custom-messages="customMessages">
                        <vs-input class="w-full" name="suburb" v-model="postCode.suburb" />
                        <span class="text-danger text-sm">{{ errors[0] }}</span>
                    </ValidationProvider>
                </vs-col>
            </vs-row>

            <vs-row class="mb-base">
                <vs-col class="m-2" vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
                    <span>State:</span>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
                    <ValidationProvider class="w-full" :rules="{ required: true, max: 100 }" v-slot="{ errors }" :custom-messages="customMessages">
                        <vs-select name="state" v-model="postCode.state" class="w-full">
                            <vs-select-item :key="index" :value="item.code" :text="item.name" v-for="(item, index) in stateList" />
                        </vs-select>
                        <span class="text-danger text-sm">{{ errors[0] }}</span>
                    </ValidationProvider>
                </vs-col>
            </vs-row>

            <vs-row class="mb-base">
                <vs-col class="m-2" vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
                    <span>Postcode:</span>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
                    <ValidationProvider class="w-full" name="Postcode" :rules="{ regex: /^[a-zA-Z0-9 -]+$/, required: true, max: 10 }" v-slot="{ errors }" :custom-messages="customMessages">
                        <vs-input class="w-full" v-model="postCode.postcode" />
                        <span class="text-danger text-sm">{{ errors[0] }}</span>
                    </ValidationProvider>
                </vs-col>
            </vs-row>

            <vs-row class="mb-base">
                <vs-col class="m-2" vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
                    <span></span>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
                    <vs-button class="mr-3 mb-2" @click.prevent="submitUser">Submit</vs-button>
                </vs-col>
            </vs-row>
        </form>
    </ValidationObserver>
</vx-card>
</template>

<script>
import {
    postcodeData
} from "../../store/api/postcode"
import {
    customMessages
} from "./../../filters/validationmessage";
import {
    treeMixData
} from "../../store/api/treeMix";
import GeoSuggest from "../../components/GeoSuggest/GeoSuggest";

export default {

    components: {
        GeoSuggest,
    },

    data() {
        return {
            customMessages,
            postCode: {},
            data: [],
            preferenceList: ["Preference 1", "Preference 2"],
            preference: ""
        };
    },

    async created() {
        this.stateList = treeMixData.searchListOfStates();
    },

    methods: {
        async parentToChild(data) {
            this.postCode = data;
        },
        async submitUser() {

            const success = await this.$refs.form.validate();
            if (!success) {
                return;
            }

            const payload = {
                suburbName: this.postCode.suburb,
                state: this.postCode.state,
                postcode: this.postCode.postcode,
                preference: this.preference
            }

            let apiResult = await postcodeData.SavePostcode(payload);
            if (!apiResult.succeeded) {
                this.$vs.notify({
                    title: "Error",
                    text: apiResult.message,
                    color: "danger",
                    position: "top-center"
                });
                return;
            }
            this.$vs.notify({
                title: "Success",
                text: "Record Saved",
                color: "success",
                position: "top-center"
            });

            this.$router.push("/admin/localpostcode");
        },
        handleBack() {
            this.$router.back();
        }
    },
};
</script>

<style scoped>
.formfield {
    padding: 11px;
    border: 1px solid #ccc;
    border-radius: 4px;
}
</style>
<style lang="scss">
#editForm {
    .vs-sidebar {
        position: fixed;
        z-index: 52000;
        min-width: 40vw;
        margin: 10px;
        max-height: 97vh;
        box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
    }
}
</style>
